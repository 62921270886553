export class EnvironmentSettings {
  private environment: Environment
  private dexGuruAPIDomain: string
  private dexGuruTradingAPIDomain: string
  private dexGuruWsDomain: string
  private dexGuruAppDomain: string
  private ogpBucketDomain: string
  public microServices: Microservics

  constructor(
    environment: Environment,
    {
      dexGuruAPIDomain,
      dexGuruTradingAPIDomain,
      dexGuruWsDomain,
      dexGuruAppDomain,
      ogpBucketDomain,
    }: {
      dexGuruAPIDomain: string
      dexGuruWsDomain: string
      dexGuruTradingAPIDomain: string
      dexGuruAppDomain: string
      ogpBucketDomain: string
    },
    { dexGuruAuthMicroserviceDomain }: { dexGuruAuthMicroserviceDomain: string }
  ) {
    this.environment = environment
    this.dexGuruAPIDomain = dexGuruAPIDomain
    this.dexGuruTradingAPIDomain = dexGuruTradingAPIDomain
    this.dexGuruWsDomain = dexGuruWsDomain
    this.dexGuruAppDomain = dexGuruAppDomain
    this.ogpBucketDomain = ogpBucketDomain
    this.microServices = new Microservics({ dexGuruAuthMicroserviceDomain })
  }

  private getAuthApiUrl = (): string =>
    this.microServices.isAuthMicroserviceInUse()
      ? this.microServices.getDexGuruAuthMicroserviceUrl()
      : this.getDexGuruAPIUrl()

  private getDexGuruAPIUrl = (): string => `https://${this.dexGuruAPIDomain}`

  public getDexGuruAPIV1Url = (): string => `${this.getDexGuruAPIUrl()}/v1`
  public getDexGuruAPIV2Url = (): string => `${this.getDexGuruAPIUrl()}/v2`
  public getDexGuruAPIV3Url = (): string => `${this.getDexGuruAPIUrl()}/v3`

  public getDexGuruAuthApiV1Url = (): string => `${this.getAuthApiUrl()}/v1`
  public getDexGuruAuthApiV2Url = (): string => `${this.getAuthApiUrl()}/v2`
  public getDexGuruAuthApiV3Url = (): string => `${this.getAuthApiUrl()}/v3`

  public getDexGuruAppUrl = (): string => `https:/'${this.dexGuruAppDomain}`

  public getWsChannelUrl = (): string => `wss://${this.dexGuruWsDomain}/v1/ws/channels`

  public getTradingAPIUrl = (): string => `https://${this.dexGuruTradingAPIDomain}`

  public getOgpBucketUrl = (): string => `https://${this.ogpBucketDomain}`
  public getAllApiOrigins = (): string[] =>
    [this.getDexGuruAPIUrl(), `https://${this.dexGuruWsDomain}`].concat(
      this.microServices.getAllOrigins()
    )
}

export enum Environment {
  production = 'production',
  local = 'local',
  test = 'test',
}

class Microservics {
  private dexGuruAuthMicroserviceDomain

  constructor({ dexGuruAuthMicroserviceDomain }: { dexGuruAuthMicroserviceDomain: string }) {
    this.dexGuruAuthMicroserviceDomain = dexGuruAuthMicroserviceDomain
  }

  public isAuthMicroserviceInUse = (): boolean =>
    process.env.REACT_APP_AUTH_MICROSERVICE?.toLocaleLowerCase() === 'true'

  public getDexGuruAuthMicroserviceUrl = (): string =>
    `https://${this.dexGuruAuthMicroserviceDomain}`

  public getAllOrigins = (): string[] => [this.getDexGuruAuthMicroserviceUrl()]
}
